export default function authHeader() {
  // Assuming 'persist:root' is the correct key for your Redux Persist configuration
  const persistedStateJSON = localStorage.getItem('persist:root')
  const persistedState = JSON.parse(persistedStateJSON)

  // Access the 'auth' reducer state
  const auth = JSON.parse(persistedState?.auth || '{}')

  if (auth.user && auth.token) {
    return { ['Authorization']: `Bearer ${auth.token}` }
  } else {
    return {}
  }
}
