// socket.js
import { io } from 'socket.io-client'

const URL =
  process.env.NODE_ENV === 'production'
    ? 'https://app.crmgurus.net'
    : 'http://localhost:3001'

const socket = io(URL, {
  cert: process.env.NODE_ENV === 'production' ? process.env.SSL_CERT : '',
  key: process.env.NODE_ENV === 'production' ? process.env.SSL_KEY : '',
  path: '/socket',
  reconnection: true,
  transports: ['websocket', 'polling'],
  reconnectionAttempts: 3,
})

export default socket
