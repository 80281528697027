import authApi from '../../utils/AxiosConfig'
import authHeader from '../../utils/authHeader'

const getAllPlatforms = async (page: number, limit: number) => {
  const response = await authApi.get(
    `/admin/platforms?page=${page}&limit=${limit}`,
    {
      headers: authHeader(),
    }
  )
  return response.data
}

const getPlatform = async (id: any) => {
  const response = await authApi.get(`/admin/platforms/${id}`, {
    headers: authHeader(),
  })
  return response.data
}

const createPlatform = async (formData: any) => {
  const response = await authApi.post('/admin/platforms', formData, {
    headers: authHeader(),
  })
  return response.data
}

const updatePlatform = async (id: any, formData: any) => {
  const response = await authApi.put(`/admin/platforms/${id}`, formData, {
    headers: authHeader(),
  })
  return response.data
}

const deletePlatform = async (id: string) => {
  const response = await authApi.delete(`/admin/platforms/${id}`, {
    headers: authHeader(),
  })
  return response.data
}

const platformService = {
  getAllPlatforms,
  getPlatform,
  createPlatform,
  updatePlatform,
  deletePlatform,
}

export default platformService
