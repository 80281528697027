import authApi from '../../utils/AxiosConfig'
import authHeader from '../../utils/authHeader'

const getAllUsers = async (formData: any) => {
  const response = await authApi.get(
    '/admin/users',
    { headers: authHeader() },
    formData
  )
  return response.data
}

const getUser = async (id: any) => {
  const response = await authApi.get(`/admin/users/${id}`, {
    headers: authHeader(),
  })
  return response.data
}

const deleteUser = async (id: any) => {
  const response = await authApi.delete(`/admin/users/${id}`, {
    headers: authHeader(),
  })
  return response.data
}

const createUser = async (formData: any) => {
  const response = await authApi.post('/admin/users', formData, {
    headers: authHeader(),
  })
  return response.data
}

const updateUser = async (formData: any) => {
  const response = await authApi.put('/admin/users', formData, {
    headers: authHeader(),
  })
  return response.data
}

const userService = {
  getAllUsers,
  createUser,
  deleteUser,
  updateUser,
  getUser,
}

export default userService
