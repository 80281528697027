import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import platformService from './platformService'

// Thunks for async actions

// Fetch all platforms
export const fetchAllPlatforms = createAsyncThunk(
  'platforms/fetchAll',
  async (
    { page, limit }: { page: number; limit: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await platformService.getAllPlatforms(page, limit)
      return response
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

// Fetch a single platform by ID
export const fetchPlatformById = createAsyncThunk(
  'platforms/fetchById',
  async (id: string, { rejectWithValue }) => {
    try {
      const data = await platformService.getPlatform(id)
      return data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

// Create a platform
export const createPlatform = createAsyncThunk(
  'platforms/create',
  async (formData: any, { rejectWithValue }) => {
    try {
      const data = await platformService.createPlatform(formData)
      return data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

// Update a platform
export const updatePlatform = createAsyncThunk(
  'platforms/update',
  async (
    { id, formData }: { id: string; formData: any },
    { rejectWithValue }
  ) => {
    try {
      const data = await platformService.updatePlatform(id, formData)
      return data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

// Delete a platform
export const deletePlatform = createAsyncThunk(
  'platforms/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      const data = await platformService.deletePlatform(id)
      return data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

// The initial state of the platform slice
const initialState = {
  platforms: [],
  platform: null,
  isLoading: false,
  isError: false,
  message: '',
}

// Platform slice
const platformSlice = createSlice({
  name: 'platforms',
  initialState,
  reducers: {
    // You can add additional non-async actions here, e.g., to clear state
    resetState: (state) => {
      state.platform = null
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all platforms
      .addCase(fetchAllPlatforms.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAllPlatforms.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.platforms = payload.data.platforms
        state.total = payload.data.total
        state.page = payload.data.page
        state.pages = payload.data.pages
      })
      .addCase(fetchAllPlatforms.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload as string
      })
      // Fetch platform by ID
      .addCase(fetchPlatformById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPlatformById.fulfilled, (state, action) => {
        state.isLoading = false
        state.platform = action.payload
      })
      .addCase(fetchPlatformById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload as string
      })
      // Create platform
      .addCase(createPlatform.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createPlatform.fulfilled, (state, action) => {
        state.isLoading = false
        state.platforms.push(action.payload) // Add the new platform to the state
      })
      .addCase(createPlatform.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload as string
      })
      // Update platform
      .addCase(updatePlatform.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updatePlatform.fulfilled, (state, action) => {
        state.isLoading = false
        const index = state.platforms.findIndex(
          (p: any) => p._id === action.payload._id
        )
        if (index !== -1) {
          state.platforms[index] = action.payload
        }
      })
      .addCase(updatePlatform.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload as string
      })
      // Delete platform
      .addCase(deletePlatform.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletePlatform.fulfilled, (state, action) => {
        state.isLoading = false
        state.platforms = state.platforms.filter(
          (p: any) => p._id !== action.meta.arg
        )
      })
      .addCase(deletePlatform.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload as string
      })
  },
})

// Export actions and reducer
export const { resetState } = platformSlice.actions
export default platformSlice.reducer
