import { createSlice } from '@reduxjs/toolkit'

// Assuming 'persist:root' is the correct key for your Redux Persist configuration
const persistedStateJSON = localStorage.getItem('persist:root')
const persistedState = JSON.parse(persistedStateJSON)

// Access the 'auth' reducer state
const { isDark } = JSON.parse(persistedState?.theme || '{}')

const InitialState = {
  isDark: isDark || false,
}

const themeSlice = createSlice({
  name: 'theme',
  initialState: InitialState,
  reducers: {
    setIsDark: (state, action) => {
      state.isDark = action.payload
    },
  },
})

export const { setIsDark } = themeSlice.actions
export default themeSlice.reducer
