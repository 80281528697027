import mixpanel from 'mixpanel-browser'

mixpanel.init('dbb3bc11043a3fc0a85de0cbeccdf67f')

const env_check: any = process.env.NODE_ENV === 'production'

const actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id)
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id)
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props)
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props)
    },
  },
}

export const Mixpanel = actions
