import axios from 'axios'
import { toast } from 'react-hot-toast'
// import persistor from "./../Store"

const BASEURL = `${process.env.API_URL}/api/v1`

const authApi = axios.create({
  baseURL: BASEURL,
  // withCredentials: true,
})

authApi.defaults.headers.common['Content-Type'] = 'application/json'

authApi.interceptors.response.use(
  (response) => {
    // handle success
    return response
    // console.log(response, "response from axios");
  },
  (error) => {
    // handle error
    if (
      error.response.message === 'Token Expired' ||
      error.response.message === 'Token Invalid'
    ) {
      window.localStorage.removeItem('persist:root')
      // setTimeout(() => {
      //     window.location.href = '/auth/login'
      // }, 2000)
      toast.error('Session Expired, Please Login Again')
    }

    if (error.response.status === 401 || error.response.status == 403) {
      // network error
      window.localStorage.removeItem('persist:root')
      toast.error('Unauthorized, Please login again')
      //   setTimeout(() => {
      //     window.location.href = '/auth/login'
      //   }, 1000)
      console.log(error, 'network error')
    } else {
      localStorage.removeItem('presist:root')
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    }

    return Promise.reject(error)
  }
)

export default authApi
