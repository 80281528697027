import authApi from '../../utils/AxiosConfig'
import authHeader from '../../utils/authHeader'

// Fetch all leads
const getAllLeads = async () => {
  const response = await authApi.get('/admin/leads', {
    headers: authHeader(),
  })
  return response.data
}

// Fetch a specific lead by ID
const getLead = async (id: any) => {
  const response = await authApi.get(`/admin/leads/${id}`, {
    headers: authHeader(),
  })
  return response.data
}

// Create a new lead
const createLead = async (formData: any) => {
  const response = await authApi.post('/admin/leads', formData, {
    headers: authHeader(),
  })
  return response.data
}

// Update an existing lead by ID
const updateLead = async (id: any, formData: any) => {
  const response = await authApi.put(`/admin/leads/${id}`, formData, {
    headers: authHeader(),
  })
  return response.data
}

// Delete a lead by ID
const deleteLead = async (id: string) => {
  const response = await authApi.delete(`/admin/leads/${id}`, {
    headers: authHeader(),
  })
  return response.data
}

// Export the lead service with all the available functions
const leadService = {
  getAllLeads,
  getLead,
  createLead,
  updateLead,
  deleteLead,
}

export default leadService
