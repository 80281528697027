import authApi from '../../utils/AxiosConfig'
import authHeader from '../../utils/authHeader'

// Create Stripe Subscription
export const createSubscription = async (token: string, priceId: string) => {
  const response = await authApi.post(
    '/subscription/create',
    {
      priceId,
    },
    {
      headers: authHeader(),
      token,
    }
  )
  return response.data
}

// Stripe Subscription Status
export const subscriptionStatus = async (token: string) => {
  const response = await authApi.get('/subscription/status', {
    headers: authHeader(),
    token,
  })
  return response.data
}

// Stripe Subscription Management
export const manageSubscription = async (token: string) => {
  const response = await authApi.get('/subscription/manage', {
    headers: authHeader(),
    token,
  })
  return response.data
}

// Cancel Stripe Subscription
// export const cancelSubscription = async (token: string) => {
//     const response = await authApi.post('/subscription/cancel', {}, {
//         headers: authHeader(),
//         token,
//     })
//     return response.data
// }

const subscriptionService = {
  createSubscription,
  subscriptionStatus,
  manageSubscription,
}

export default subscriptionService
