import authHeader from '../../utils/authHeader'
import authApi from '../../utils/AxiosConfig'

const API_URL = `${process.env.API_URL}/api/v1/seller/projects`

// get all user projects
const getAllSellerProjects = async () => {
  const response = await authApi.get(API_URL + '/?limit=10', {
    headers: authHeader(),
  })
  return response.data
}

const likeProject = async (projectId) => {
  const response = await authApi.patch(
    `${API_URL}/${projectId}/like`,
    {},
    {
      headers: authHeader(),
    }
  )
  return response.data
}

const sellerProjectService = {
  getAllSellerProjects,
  likeProject,
}

export default sellerProjectService
