import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'
import proposalService from './proposalService'

interface IinitialState {
  data: any
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  message: any
}

const initialState: IinitialState = {
  data: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
}

export const getAllProposals = createAsyncThunk<any, any, { state: RootState }>(
  'proposal/getAllProposals',
  async (id, thunkAPI) => {
    try {
      return await proposalService.getAllProposals(id)
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const proposalSlice = createSlice({
  name: 'proposal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProposals.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.isSuccess = false
      })
      .addCase(getAllProposals.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.data = payload.data
      })
      .addCase(getAllProposals.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
  },
})

export default proposalSlice.reducer
