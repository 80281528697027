import authHeader from '../../utils/authHeader'
import authApi from '../../utils/AxiosConfig'

const API_URL = `${process.env.API_URL}/api/v1/projects`

// Create a new project
const createProposal = async (formData: any, id: string) => {
  const response = await authApi.post(
    API_URL + '/' + id + '/' + 'proposals',
    formData,
    {
      headers: authHeader(),
    }
  )
  return response.data
}

// get all projects
const getAllProposals = async (id: string) => {
  const response = await authApi.get(API_URL + '/' + id + '/proposals', {
    headers: authHeader(),
  })
  return response.data
}

const proposalService = {
  createProposal,
  getAllProposals,
}

export default proposalService
