import { createSlice } from '@reduxjs/toolkit'

export const socketSlice = createSlice({
  name: 'socket',
  initialState: {
    socketId: null,
    isConnected: false,
    loggedInUser: null,
  },
  reducers: {
    setSocket: (state, action) => {
      state.socketId = action.payload
      state.isConnected = action.payload !== null
    },
    disconnectSocket: (state) => {
      state.isConnected = false
    },
    userLoggedIn: (state, action) => {
      state.loggedInUser = action.payload || null
    },
  },
})

export const { setSocket, disconnectSocket, userLoggedIn } = socketSlice.actions
export const selectSocket = (state) => state.socket

export default socketSlice.reducer
