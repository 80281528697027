import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import sellerProjectService from './sellerProjectService'

interface IinitialState {
  projects: any
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  message: any
}

const initialState: IinitialState = {
  projects: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const getAllSellerProjects = createAsyncThunk<
  any,
  any,
  { state: RootState }
>('sellerProjects/getAllSellerProjects', async (data, thunkAPI) => {
  try {
    return await sellerProjectService.getAllSellerProjects()
  } catch (error: any) {
    console.log(error)
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const likeProject = createAsyncThunk(
  'sellerProjects/likeProject',
  async (projectId: string, thunkAPI) => {
    try {
      return await sellerProjectService.likeProject(projectId)
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const sellerProjectSlice = createSlice({
  name: 'sellerProjects',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSellerProjects.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAllSellerProjects.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.isSuccess = true
      state.projects = payload.data
    })
    builder.addCase(getAllSellerProjects.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(likeProject.fulfilled, (state, { payload }) => {
      const updatedProject = payload.data
      const projectIndex = state.projects.findIndex(
        (project) => project._id === updatedProject._id
      )
      if (projectIndex !== -1) {
        state.projects[projectIndex] = updatedProject // Update the project in the state
      }
    })
  },
})

// Actions
export const { reset } = sellerProjectSlice.actions
export default sellerProjectSlice.reducer
