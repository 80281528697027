import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stripeService from './stripeService'

interface IinitialState {
  accountBalance: null
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  message: any
}

const initialState: IinitialState = {
  accountBalance: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const getAccountStatus = createAsyncThunk(
  'stripe/balance',
  async (_, thunkAPI) => {
    try {
      return await stripeService.accountBalance()
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccountStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAccountStatus.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.accountBalance = action.payload.data
      })
      .addCase(getAccountStatus.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export default stripeSlice.reducer
