import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import userService from './userService'
import { RootState } from '..'
import toast from 'react-hot-toast'

type IUser = {
  id: string
  name: string
  email: string
  role: string
  createdAt: string
  updatedAt: string
}

interface IinitialState {
  users: IUser[]
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  message: any
}

const initialState: IinitialState = {
  users: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
}

export const createUser = createAsyncThunk<IUser, any, { state: RootState }>(
  'users/createUser',
  async (data, thunkAPI) => {
    try {
      return await userService.createUser(data)
    } catch (error: any) {
      console.log(error)
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getAllUsers = createAsyncThunk<IUser[], any, { state: RootState }>(
  'users/getAllUsers',
  async (data, thunkAPI) => {
    try {
      return await userService.getAllUsers(data)
    } catch (error: any) {
      console.log(error)
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getUser = createAsyncThunk<IUser, any, { state: RootState }>(
  'users/getUser',
  async (data, thunkAPI) => {
    try {
      return await userService.getUser(data)
    } catch (error: any) {
      console.log(error)
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateUser = createAsyncThunk<any, any, { state: RootState }>(
  'users/updateUser',
  async (data, thunkAPI) => {
    try {
      return await userService.updateUser(data)
    } catch (error: any) {
      console.log(error)
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteUser = createAsyncThunk<any, any, { state: RootState }>(
  'users/deleteUser',
  async (data, thunkAPI) => {
    try {
      return await userService.deleteUser(data)
    } catch (error: any) {
      console.log(error)
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString()
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = true
        state.users = payload.data
      })
      .addCase(getAllUsers.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isError = true
        state.message = payload.message
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = true
        state.users = payload.data
      })
      .addCase(getUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isError = true
        state.message = payload
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = payload
      })
      .addCase(deleteUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isError = true
        state.message = payload
      })
  },
})

export default userSlice.reducer
