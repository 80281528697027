import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import leadService from './leadService'
import { RootState } from '../../store'

// Define the state interface
interface LeadState {
  leads: any[]
  lead: any | null
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  message: string | null
}

// Initial state
const initialState: LeadState = {
  leads: [],
  lead: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: null,
}

// Async Thunks
export const getAllLeads = createAsyncThunk(
  'leads/getAllLeads',
  async (_, thunkAPI) => {
    try {
      const response = await leadService.getAllLeads()
      return response
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || error.message
      )
    }
  }
)

export const getLead = createAsyncThunk(
  'leads/getLead',
  async (id: string, thunkAPI) => {
    try {
      const response = await leadService.getLead(id)
      return response
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || error.message
      )
    }
  }
)

export const createLead = createAsyncThunk(
  'leads/createLead',
  async (formData: any, thunkAPI) => {
    try {
      const response = await leadService.createLead(formData)
      return response
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || error.message
      )
    }
  }
)

export const updateLead = createAsyncThunk(
  'leads/updateLead',
  async ({ id, formData }: { id: string; formData: any }, thunkAPI) => {
    try {
      const response = await leadService.updateLead(id, formData)
      return response
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || error.message
      )
    }
  }
)

export const deleteLead = createAsyncThunk(
  'leads/deleteLead',
  async (id: string, thunkAPI) => {
    try {
      await leadService.deleteLead(id)
      return id
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || error.message
      )
    }
  }
)

// Lead Slice
export const leadSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    resetState: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLeads.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllLeads.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.leads = action.payload.data
      })
      .addCase(getAllLeads.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload as string
      })
      .addCase(getLead.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getLead.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.lead = action.payload
      })
      .addCase(getLead.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload as string
      })
      .addCase(createLead.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createLead.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.leads.push(action.payload)
      })
      .addCase(createLead.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload as string
      })
      .addCase(updateLead.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateLead.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.leads = state.leads.map((lead) =>
          lead._id === action.payload._id ? action.payload : lead
        )
      })
      .addCase(updateLead.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload as string
      })
      .addCase(deleteLead.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteLead.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.leads = state.leads.filter((lead) => lead._id !== action.payload)
      })
      .addCase(deleteLead.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload as string
      })
  },
})

// Export reducer actions
export const { resetState } = leadSlice.actions

// Export lead reducer
export default leadSlice.reducer

// Selector to get leads from state
export const selectAllLeads = (state: RootState) => state.leads.leads
