import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import subscriptionService from './subscriptionService'

interface IinitialState {
  data: any
  subscriptionStatus: any
  sessionUrl: any
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  message: any
}

const initialState: IinitialState = {
  data: null,
  subscriptionStatus: null,
  sessionUrl: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
}

export const getSubscriptionStatus = createAsyncThunk(
  'subscription/status',
  async (thunkAPI) => {
    try {
      return await subscriptionService.subscriptionStatus()
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const subscriptionManagement = createAsyncThunk(
  'subscription/manage',
  async (thunkAPI) => {
    try {
      return await subscriptionService.manageSubscription()
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getSubscriptionStatus.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = true
        state.data = payload.data?.subscriptions || null
        state.subscriptionStatus =
          payload?.data?.subscriptions[0]?.status || false
      })
      .addCase(getSubscriptionStatus.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isError = true
        state.message = payload
      })
      .addCase(subscriptionManagement.pending, (state) => {
        state.isLoading = true
      })
      .addCase(subscriptionManagement.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = true
        state.sessionUrl = payload.data?.url || null
      })
      .addCase(subscriptionManagement.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isError = true
        state.message = payload
      })
  },
})

export default subscriptionSlice.reducer
