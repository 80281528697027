import authApi from '../../utils/AxiosConfig'
import authHeader from '../../utils/authHeader'

// Stripe Connect Account
export const connectStripe = async () => {
  const response = await authApi.post(
    '/stripe/connect',
    {},
    {
      headers: authHeader(),
    }
  )
  return response.data
}

// Stripe Connect Account Status
export const statusStripe = async () => {
  const response = await authApi.post(
    '/stripe/status',
    {},
    {
      headers: authHeader(),
    }
  )
  return response.data
}

export const payoutSettings = async () => {
  const response = await authApi.get('/stripe/payout-settings', {
    headers: authHeader(),
  })
  return response.data
}

const accountBalance = async () => {
  const response = await authApi.get('/stripe/balance', {
    headers: authHeader(),
  })
  return response.data
}

export const updateUserInLocalStorage = (user: any, next: () => void) => {
  if (window.localStorage.getItem('persist:root')) {
    const auth = JSON.parse(localStorage.getItem('persist:root') || '{}')
    console.log(auth)
    const newData = { ...auth, user }
    console.log(newData)
    localStorage.setItem('persist:root', JSON.stringify(newData))
    next()
  }
}

const stripeService = {
  accountBalance,
  statusStripe,
}

export default stripeService
